import { formatAddress, Restaurant, SearchService } from 'gc-common';
import React, { useEffect, useState } from 'react';
import { ScrollView, Text, View } from 'react-native';
import { RowItem } from '../../components/RowItem/RowItem';
import { CommonStyles } from '../../components/Styles/CommonStyles';
import { useAuth } from '../../context/authContext';
import { useModal } from '../../context/modalContext';
import { RootStackScreenProps } from '../../types';
import { DEFAULT_RESTAURANT_IMAGE } from '../../types/Constants';
import { styles as StoreRowStyles } from '../PostCreationPage/styles';

export const RestaurantsPage = ({
    route,
    navigation,
}: RootStackScreenProps<'SearchRestaurant'>) => {
    const modal = useModal();
    const auth = useAuth();

    const fetchData = async (keyword: string) => {
        modal.open('loader');

        const rsp = await SearchService.search(keyword, 'all', auth.location);
        const _restaurants = [] as Restaurant[];

        rsp.data.forEach((item) => {
            if (item.search_type === 'restaurant') _restaurants.push(item);
        });

        setRestaurants(_restaurants);
        modal.close();
    };

    useEffect(() => {
        fetchData(route.params?.keyword);
    }, []);

    const [restaurants, setRestaurants] = useState<Restaurant[]>([]);

    return (
        <View style={{ flex: 1 }}>
            <ScrollView
                keyboardShouldPersistTaps={'handled'}
                automaticallyAdjustKeyboardInsets={true}
            >
                <View style={[CommonStyles.section, { paddingTop: 60 }]}>
                    <View style={CommonStyles.titleRow}>
                        <Text style={CommonStyles.title}>Restaurants</Text>
                    </View>
                    <View>
                        {restaurants.map((item) => (
                            <View style={StoreRowStyles.rowItemWrapper} key={item?._id}>
                                <RowItem
                                    image={item.avatar_image_url || DEFAULT_RESTAURANT_IMAGE}
                                    title={item.name}
                                    subTitle={formatAddress(item.address)}
                                    onPress={() => {
                                        navigation.navigate('RestaurantDetail', {
                                            id: item.restaurant_id,
                                        });
                                    }}
                                />
                            </View>
                        ))}
                    </View>
                </View>
            </ScrollView>
        </View>
    );
};
