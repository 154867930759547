/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { Feather } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer, NavigationContainerRef } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { TransitionPresets } from '@react-navigation/stack';
import { requestTrackingPermissionsAsync } from 'expo-tracking-transparency';
import * as React from 'react';
import { ColorSchemeName, DeviceEventEmitter, Image, Platform } from 'react-native';

import { useEffect } from 'react';
import appsFlyer from 'react-native-appsflyer';
import { AddPostButton } from '../components/AddPostButton/AddPostButton';
import { AnimatedTopHeader } from '../components/AnimatedTopHeader';
import { HomeScreenTopHeader } from '../components/HomeScreenTopHeader';
import { DishCreationPage } from '../components/Menu/DishCreationPage';
import { Colors } from '../constants/Colors';
import { useAuth } from '../context/authContext';
import { Env } from '../Env';
import useColorScheme from '../hooks/useColorScheme';
import { useNotification } from '../hooks/useNotification';
import CollectionNoLogin from '../screens/Collection/CollectionNoLogin';
import { CollectionPage } from '../screens/Collection/CollectionPage';
import ComingSoonPage from '../screens/ComingSoonPage';
import { DishDetailScreen } from '../screens/DishDetail/DishDetailScreen';
import { ExploreScreen } from '../screens/Explore/ExploreScreen';
import { HomeScreen } from '../screens/Home/HomeScreen';
import { EditProfilePage } from '../screens/Me/EditProfilePage';
import { MeFollow } from '../screens/Me/MeFollow';
import { MePage } from '../screens/Me/MePage';
import { NotificationPage } from '../screens/Me/NotificationPage';
import { useNotify } from '../screens/Me/useNotify';
import ModalScreen from '../screens/ModalScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import { PostPage } from '../screens/Post/PostPage';
import { DishSelectPage } from '../screens/PostCreationPage/DishSelectPage';
import { PostCreationPage } from '../screens/PostCreationPage/PostCreationPage';
import { RestaurantCreationPage } from '../screens/PostCreationPage/RestaurantCreationPage';
import { RestaurantSelectPage } from '../screens/PostCreationPage/RestaurantSelectPage';
import { ContactUsPage } from '../screens/PrivacyPolicy/ContactUsPage';
import { DeleteAccountPage } from '../screens/PrivacyPolicy/DeleteAccountPage';
import { PrivacyPolicyScreen } from '../screens/PrivacyPolicy/PrivacyPolicyScreen';
import { RedirectPage } from '../screens/Redirect/Redirect';
import { MenuPage } from '../screens/RestaurantDetail/MenuPage';
import { RestaurantDetailScreen } from '../screens/RestaurantDetail/RestaurantDetailScreen';
import { RestaurantsPage } from '../screens/Search/RestaurantsPage';
import { SearchPage } from '../screens/Search/SearchPage';
import SettingsScreen from '../screens/Settings/SettingsScreen';
import SignInScreen from '../screens/SignIn/SignInScreen';
import PasswordScreen from '../screens/SignUp/PasswordScreen';
import SignUpScreen from '../screens/SignUp/SignUpScreen';
import { EulaScreen } from '../screens/TermsOfService/EULA';
import { TermsOfServiceScreen } from '../screens/TermsOfService/TermsOfServiceScreen';
import { TrendngCusineScreen } from '../screens/TrendingCuisine/TrendingCusineScreen';
import { WebviewScreen } from '../screens/Webview/Webview';
import { useFeatureToggle } from '../states/useFeatureToggle';
import { RootStackParamList, RootTabParamList, RootTabScreenProps } from '../types';
import { Logger } from '../utils/logger';
import { DdRumReactNavigationTracking } from '../webLibs/Datadog';
import { analytics } from '../webLibs/Firebase/firebase';
import LinkingConfiguration from './LinkingConfiguration';
import styles from './styles';

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
    const navigationRef = React.useRef<NavigationContainerRef<any>>(null);
    const routeNameRef = React.useRef<string>();
    if (Env.isNative) useNotification(navigationRef);

    const aatPermission = async () => {
        const { status } = await requestTrackingPermissionsAsync();
        if (status === 'granted') {
            console.log('Yay! I have user permission to track data');
        }
    };

    const initAppsFlyer = () => {
        Logger.log('Initting AppsFlyer...');
        appsFlyer.onDeepLink((data) => {
            const page = data.data.deep_link_value;
            const pageData = data.data.deep_link_sub1;
            Logger.log('AppsFlyer DL received...');
            Logger.log('DL callbacked data>', {
                dlv: page,
                dls: pageData,
            });
            Logger.log('DL callbacked gc_dl data>', {
                gc_dl: data.data.gc_dl,
                gc_dl_arg_k1: data.data.gc_dl_arg_k1,
                gc_dl_arg_v1: data.data.gc_dl_arg_v1,
            });

            if (data.data.gc_dl) {
                navigationRef.current?.navigate(data.data.gc_dl, {
                    [data.data.gc_dl_arg_k1]: data.data.gc_dl_arg_v1,
                });
                return;
            }

            if (!page) return;
            navigationRef.current?.navigate(
                data.data.deep_link_value,
                JSON.parse(decodeURIComponent(pageData || '{}'))
            );
        });

        Env.isNative &&
            appsFlyer.initSdk(
                {
                    devKey: 'Jjpafk4SKyqc7549fB5ewM',
                    isDebug: false,
                    appId: '1673184423',
                    onInstallConversionDataListener: true, //Optional
                    onDeepLinkListener: true, //Optional
                    timeToWaitForATTUserAuthorization: 10, //for iOS 14.5
                },
                (result) => {
                    console.log('appsFlyer success=>', result);
                },
                (error) => {
                    console.error(error);
                }
            );
    };
    useEffect(() => {
        if (Env.isWeb) return;
        (async () => {
            await aatPermission();
            await initAppsFlyer();
        })();
    }, []);

    return (
        <NavigationContainer
            ref={navigationRef}
            linking={LinkingConfiguration}
            onReady={() => {
                Platform.OS !== 'web' &&
                    DdRumReactNavigationTracking.startTrackingViews(navigationRef.current);
                routeNameRef.current = navigationRef?.current?.getCurrentRoute()?.name;
            }}
            onStateChange={async () => {
                const previousRouteName = routeNameRef.current;
                const currentRouteName = navigationRef?.current?.getCurrentRoute()?.name;

                if (previousRouteName !== currentRouteName) {
                    if (Env.isNative) {
                        await analytics().logScreenView({
                            screen_name: currentRouteName,
                            screen_class: currentRouteName,
                        });
                    } else {
                        await analytics().logEvent('screen_view', {
                            firebase_screen: currentRouteName,
                            firebase_screen_class: currentRouteName,
                        });
                    }
                }
                routeNameRef.current = currentRouteName;
                DeviceEventEmitter.emit('route-change', currentRouteName);
            }}
            //theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
        >
            <RootNavigator />
        </NavigationContainer>
    );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
// const Stack =
//     Platform.OS === 'android'
//         ? createStackNavigator<RootStackParamList>()
//         : createNativeStackNavigator<RootStackParamList>();
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
    const features = useFeatureToggle();
    return (
        <Stack.Navigator
            initialRouteName={'Root'}
            screenOptions={{
                headerStyle: {
                    backgroundColor: Colors.white,
                },
                headerTitleStyle: { color: Colors.charcoal },
                headerTintColor: Colors.charcoal,
                headerShadowVisible: true,
            }}
        >
            <Stack.Screen
                name="Root"
                component={BottomTabNavigator}
                options={{ headerShown: false }}
            />
            <Stack.Screen name="RedirectPage" component={RedirectPage} />
            <Stack.Screen
                name="DishDetail"
                component={DishDetailScreen}
                getId={({ params }) => params?.id}
                options={{
                    header: AnimatedTopHeader as any,
                    headerTitle: 'Dish Details',
                }}
            />
            <Stack.Screen
                name="PostDetail"
                component={PostPage}
                getId={({ params }) => params?.id}
                options={{
                    // header: AnimatedTopHeader,
                    headerTitle: 'Post Details',
                }}
            />
            <Stack.Screen
                name="RestaurantDetail"
                component={RestaurantDetailScreen}
                getId={({ params }) => params?.id}
                options={{ headerTitle: 'Restaurant Detail', header: AnimatedTopHeader as any }}
            />
            <Stack.Screen name="PostCreation" component={PostCreationPage} />
            <Stack.Screen
                name="RestaurantSelect"
                component={RestaurantSelectPage}
                options={
                    {
                        headerShadowVisible: false,
                        presentation: 'modal',
                        ...(Platform.OS == 'android' && TransitionPresets.ModalPresentationIOS),
                    } as any
                }
            />
            <Stack.Screen
                name="RestaurantCreation"
                component={RestaurantCreationPage}
                options={
                    {
                        headerShadowVisible: false,
                        presentation: 'modal',
                        ...(Platform.OS == 'android' && TransitionPresets.ModalPresentationIOS),
                    } as any
                }
            />
            <Stack.Screen
                name="DishSelect"
                component={DishSelectPage}
                options={
                    {
                        headerShadowVisible: false,
                        presentation: 'modal',
                        ...(Platform.OS == 'android' && TransitionPresets.ModalPresentationIOS),
                    } as any
                }
            />
            <Stack.Screen
                name="DishCreation"
                component={DishCreationPage}
                options={
                    {
                        headerShadowVisible: false,
                        presentation: 'modal',
                        ...(Platform.OS == 'android' && TransitionPresets.ModalPresentationIOS),
                    } as any
                }
            />
            <Stack.Screen
                name="Menu"
                component={MenuPage}
                getId={({ params }) => params?.restaurantId}
                options={
                    {
                        headerShadowVisible: false,
                    } as any
                }
            />
            <Stack.Screen
                name="User"
                component={MePage}
                getId={({ params }) => params?.id}
                options={{
                    headerShown: false,
                }}
            />
            <Stack.Screen
                name="MeFollow"
                component={MeFollow}
                options={{
                    // header: AnimatedTopHeader,
                    headerTitle: 'Follow',
                    headerShadowVisible: false,
                }}
            />
            <Stack.Screen
                name="EditProfile"
                component={EditProfilePage}
                options={{ headerTitle: 'Profile' }}
            />
            <Stack.Screen
                name="Notification"
                component={features.isEnabled('notification') ? NotificationPage : ComingSoonPage}
                options={{ headerTitle: 'Notification' }}
            />
            <Stack.Screen name="TrendingCuisine" component={TrendngCusineScreen} />
            <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
            <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicyScreen} />
            <Stack.Screen name="ContactUs" component={ContactUsPage} />
            <Stack.Screen name="DeleteAccount" component={DeleteAccountPage} />
            <Stack.Screen name="TermsOfService" component={TermsOfServiceScreen} />
            <Stack.Screen name="EULA" component={EulaScreen} />
            <Stack.Screen name="Settings" component={SettingsScreen} />
            <Stack.Screen name="Webview" component={WebviewScreen} options={{ title: '' }} />
            <Stack.Screen
                name="Search"
                component={SearchPage}
                options={({ navigation }) => ({
                    headerShown: false,
                })}
            />
            <Stack.Screen
                name="SearchRestaurant"
                component={RestaurantsPage}
                getId={({ params }) => params?.keyword}
                options={({ navigation }) => ({
                    headerTitle: 'Search Restaurant',
                    header: AnimatedTopHeader as any,
                })}
            />
            <Stack.Screen
                name="SignInStack"
                component={SignInScreen}
                options={{
                    headerShown: false,
                }}
            />
            <Stack.Screen
                name="SignUp"
                component={SignUpScreen}
                options={{
                    headerShown: false,
                }}
            />
            <Stack.Screen
                name="PasswordReset"
                component={PasswordScreen}
                options={{
                    headerShown: false,
                    animation: 'slide_from_bottom',
                    animationDuration: 300,
                    presentation: 'modal',
                }}
            />
            <Stack.Group screenOptions={{ presentation: 'modal' }}>
                <Stack.Screen name="Modal" component={ModalScreen} />
            </Stack.Group>
        </Stack.Navigator>
    );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator() {
    const colorScheme = useColorScheme();
    const auth = useAuth();
    const notify = useNotify();
    useEffect(() => {
        auth.isLoggedIn && notify.getUnreadCount();
    }, []);

    return (
        <BottomTab.Navigator
            initialRouteName="Home"
            screenOptions={{
                tabBarActiveTintColor: Colors.primary,
                tabBarInactiveTintColor: Colors.gray,
                tabBarLabelPosition: 'below-icon',
            }}
        >
            <BottomTab.Screen
                name={'Home'}
                component={HomeScreen}
                initialParams={{}}
                options={({ navigation }: RootTabScreenProps<'Home'>) => {
                    return {
                        title: 'Home',
                        header: (props) => {
                            return <HomeScreenTopHeader navigation={navigation} />;
                        },

                        tabBarIcon: () => (
                            <Image
                                source={require('../../assets/images/icon.png')}
                                style={styles.icon}
                            />
                        ),
                    };
                }}
            />
            <BottomTab.Screen
                name="Explore"
                component={ExploreScreen}
                options={({ navigation }: RootTabScreenProps<'Explore'>) => {
                    return {
                        headerShown: false,
                        tabBarIcon: ({ color }) => <TabBarIcon name="compass" color={color} />,
                    };
                }}
            />
            <BottomTab.Screen
                name="PostCreationTab"
                children={() => <></>}
                options={({ navigation }: RootTabScreenProps<any>) => {
                    return {
                        tabBarButton: () => <AddPostButton navigation={navigation} />,
                    };
                }}
            />
            <BottomTab.Screen
                name="Collections"
                component={auth.isLoggedIn ? CollectionPage : CollectionNoLogin}
                options={{ tabBarIcon: ({ color }) => <TabBarIcon name="star" color={color} /> }}
            />

            {auth.isLoggedIn && (
                <BottomTab.Screen
                    name="Me"
                    component={MePage}
                    options={{
                        headerShown: false,
                        tabBarIcon: ({ color }) => <TabBarIcon name="user" color={color} />,
                        headerTitle: 'Me',
                        header: AnimatedTopHeader as any,
                        tabBarBadge: notify.unreadCount > 0 ? notify.unreadCount : undefined,
                        tabBarBadgeStyle: { backgroundColor: Colors.primary, fontSize: 11 },
                    }}
                />
            )}
            {!auth.isLoggedIn && (
                <BottomTab.Screen
                    name="SignIn"
                    component={SignInScreen}
                    options={{
                        headerShown: false,
                        tabBarIcon: ({ color }) => <TabBarIcon name="user" color={color} />,
                    }}
                />
            )}
        </BottomTab.Navigator>
    );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: { name: React.ComponentProps<typeof Feather>['name']; color: string }) {
    return <Feather size={30} style={{ marginBottom: -3 }} {...props} />;
}
